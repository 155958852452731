import { DotFilledIcon } from '@radix-ui/react-icons';

import { footerData } from '@src/lib/constants';
import { blastLogo } from '@src/lib/imgUrl';
import { StyledFullWidthWrapper } from '@src/styles/mainLayoutWrapper';

import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';

import {
  Container,
  StyledBlastLogo,
  StyledContactContainer,
  StyledContentContainer,
  StyledElementOuter,
  StyledFooterContainer,
  StyledHeading,
  StyledHR,
  StyledLink,
  StyledLogosContainer,
  StyledSmallText,
  StyledText,
  StyledTextIconWrapper,
  StyledTextWithIconContainer,
} from './styles';
import { IconProps } from './types';

const TextWithIcon = ({ icon, text }: { icon?: IconProps; text: string }) => (
  <StyledTextWithIconContainer>
    {icon && <img src={icon?.url} alt={icon?.alt} height={24} />}
    <StyledText>{text}</StyledText>
  </StyledTextWithIconContainer>
);

const Footer = () => {
  return (
    <StyledFullWidthWrapper>
      <StyledFooterContainer>
        <MaxWidthContainer extraStyle={{ flexDirection: 'column', gap: '40px' }}>
          <Container>
            <StyledContactContainer>
              <StyledBlastLogo src={blastLogo} height={48} alt='blast logo' />
              <StyledTextIconWrapper>
                {footerData?.contactInfo?.map(({ text, icon }, index) => (
                  <TextWithIcon icon={icon} text={text} key={`${text}-${index}`} />
                ))}
              </StyledTextIconWrapper>
            </StyledContactContainer>

            {footerData?.footerElements?.map(({ heading, leftElements, rightElements, dotIcon }, index) => (
              <StyledContentContainer key={`${heading}-${index}`}>
                <StyledHeading dotIcon={dotIcon}> {heading}</StyledHeading>
                <StyledElementOuter>
                  {leftElements && (
                    <StyledContentContainer>
                      {leftElements?.map((element, i) => (
                        <StyledLink to={element.link || '#'} key={`${element.text}-${i}`}>
                          <StyledText>
                            {dotIcon && <DotFilledIcon />} {element?.text}
                          </StyledText>
                        </StyledLink>
                      ))}
                    </StyledContentContainer>
                  )}
                  {rightElements && (
                    <StyledContentContainer>
                      {rightElements?.map((element, elementIndex) => (
                        <StyledLink to={element.link || '#'} key={`${element.text}-${elementIndex}`}>
                          <StyledText>
                            {dotIcon && <DotFilledIcon />} {element.text}
                          </StyledText>
                        </StyledLink>
                      ))}
                    </StyledContentContainer>
                  )}
                </StyledElementOuter>
              </StyledContentContainer>
            ))}
          </Container>

          <StyledHR />

          <Container>
            {footerData?.sectionTwoData?.map((data, index) =>
              data?.isMulti ? (
                <Container key={`${data?.isMulti}-${index}`}>
                  {data?.content?.map(({ text, link }) => (
                    <StyledLink to={link || '#'} key={`${text}-${link}`}>
                      <StyledSmallText $color={data?.color}>{text}</StyledSmallText>
                    </StyledLink>
                  ))}
                </Container>
              ) : (
                <StyledSmallText $color={data?.color} key={`${data?.isMulti}-${index}`}>
                  {data?.text || ''}
                </StyledSmallText>
              )
            )}
          </Container>

          <StyledLogosContainer>
            {footerData?.logoSection?.map((data, dataIndex) => (
              <img key={`logo${dataIndex}`} src={data.logo} alt='footer logo' />
            ))}
          </StyledLogosContainer>
        </MaxWidthContainer>
      </StyledFooterContainer>
    </StyledFullWidthWrapper>
  );
};

export default Footer;
